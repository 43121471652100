/* eslint-disable import/no-named-as-default */

import Accessories from '@honda-canada/sitecore-jss-components/lib/components/Accessories';
import AnchorContainer from '@honda-canada/sitecore-jss-components/lib/components/AnchorContainer';
import AppBanner from '@honda-canada/sitecore-jss-components/lib/components/AppBanner';
import AppCard from '@honda-canada/sitecore-jss-components/lib/components/AppCard';
import AppHero from '@honda-canada/sitecore-jss-components/lib/components/AppHero';
import AvailableColors from '@honda-canada/sitecore-jss-components/lib/components/AvailableColors';
import Awards from '@honda-canada/sitecore-jss-components/lib/components/Awards';
import BrandNavigation from '@honda-canada/sitecore-jss-components/lib/components/BrandNavigation';
import CarouselCardsContainer from '@honda-canada/sitecore-jss-components/lib/components/CarouselCardsContainer';
import CarouselHero from '@honda-canada/sitecore-jss-components/lib/components/CarouselHero';
import CarouselWithModalContainer from '@honda-canada/sitecore-jss-components/lib/components/CarouselWithModalContainer';
import CollapsibleContainer from '@honda-canada/sitecore-jss-components/lib/components/CollapsibleContainer';
import ColorDisplay from '@honda-canada/sitecore-jss-components/lib/components/ColorDisplay';
import CompareTrimSpecifications from '@honda-canada/sitecore-jss-components/lib/components/CompareTrimSpecifications';
import ConfigureBap from '@honda-canada/sitecore-jss-components/lib/components/ConfigureBap';
import ContactUs from '@honda-canada/sitecore-jss-components/lib/components/ContactUs';
import CreditAndSignupCtas from '@honda-canada/sitecore-jss-components/lib/components/CreditAndSignupCtas';
import CtaCard from '@honda-canada/sitecore-jss-components/lib/components/CtaCard';
import CustomLayoutFactory from '@honda-canada/sitecore-jss-components/lib/components/CustomLayoutFactory';
import CustomizationSummary from '@honda-canada/sitecore-jss-components/lib/components/CustomizationSummary';
import DealerLocator from '@honda-canada/sitecore-jss-components/lib/components/DealerLocator';
import DiagonalCard from '@honda-canada/sitecore-jss-components/lib/components/DiagonalCard';
import DiagonalCardContainer from '@honda-canada/sitecore-jss-components/lib/components/DiagonalCardContainer';
import DividerLine from '@honda-canada/sitecore-jss-components/lib/components/DividerLine';
import DropdownFilterContainer from '@honda-canada/sitecore-jss-components/lib/components/DropdownFilterContainer';
import DropdownToggleContainer from '@honda-canada/sitecore-jss-components/lib/components/DropdownToggleContainer';
import DropdownToggleItem from '@honda-canada/sitecore-jss-components/lib/components/DropdownToggleItem';
import FAQ from '@honda-canada/sitecore-jss-components/lib/components/FAQ';
import Footer from '@honda-canada/sitecore-jss-components/lib/components/Footer';
import FourColumnLayoutContainer from '@honda-canada/sitecore-jss-components/lib/components/FourColumnLayoutContainer';
import FreeFormText from '@honda-canada/sitecore-jss-components/lib/components/FreeFormText';
import FullWidthCard from '@honda-canada/sitecore-jss-components/lib/components/FullWidthCard';
import FutureVehicleCard from '@honda-canada/sitecore-jss-components/lib/components/FutureVehicleCard';
import Gallery from '@honda-canada/sitecore-jss-components/lib/components/Gallery';
import Header from '@honda-canada/sitecore-jss-components/lib/components/Header';
import Hero from '@honda-canada/sitecore-jss-components/lib/components/Hero';
import HoverCard from '@honda-canada/sitecore-jss-components/lib/components/HoverCard';
import Icon from '@honda-canada/sitecore-jss-components/lib/components/Icon';
import IconCard from '@honda-canada/sitecore-jss-components/lib/components/IconCard';
import KeyFeatures from '@honda-canada/sitecore-jss-components/lib/components/KeyFeatures';
import LandingPageCard from '@honda-canada/sitecore-jss-components/lib/components/LandingPageCard';
import LandscapeBoxCard from '@honda-canada/sitecore-jss-components/lib/components/LandscapeBoxCard';
import LandscapeCard from '@honda-canada/sitecore-jss-components/lib/components/LandscapeCard';
import LayoutContainer from '@honda-canada/sitecore-jss-components/lib/components/LayoutContainer';
import LeadsCtas from '@honda-canada/sitecore-jss-components/lib/components/LeadsCtas';
import LinkPackageCard from '@honda-canada/sitecore-jss-components/lib/components/LinkPackageCard';
import ModelCard from '@honda-canada/sitecore-jss-components/lib/components/ModelCard';
import ModelCardGrid from '@honda-canada/sitecore-jss-components/lib/components/ModelCardGrid';
import ModelCardWithTrims from '@honda-canada/sitecore-jss-components/lib/components/ModelCardWithTrims';
import ModelFeatures from '@honda-canada/sitecore-jss-components/lib/components/ModelFeatures';
import ModelListFilters from '@honda-canada/sitecore-jss-components/lib/components/ModelListFilters';
import ModelPageNav from '@honda-canada/sitecore-jss-components/lib/components/ModelPageNav';
import NotificationPopup from '@honda-canada/sitecore-jss-components/lib/components/NotificationPopup/NotificationPopup';
import PackageCard from '@honda-canada/sitecore-jss-components/lib/components/PackageCard';
import PageNotification from '@honda-canada/sitecore-jss-components/lib/components/PageNotification';
import Parallax from '@honda-canada/sitecore-jss-components/lib/components/Parallax';
import PlanCard2 from '@honda-canada/sitecore-jss-components/lib/components/PlanCard2';
import PortraitCard from '@honda-canada/sitecore-jss-components/lib/components/PortraitCard';
import PreOrderBanner from '@honda-canada/sitecore-jss-components/lib/components/PreOrderBanner';
import ProgressBar from '@honda-canada/sitecore-jss-components/lib/components/ProgressBar';
import PromoBanner from '@honda-canada/sitecore-jss-components/lib/components/PromoBanner';
import QuickLink from '@honda-canada/sitecore-jss-components/lib/components/QuickLink';
import RecommendedVehicles from '@honda-canada/sitecore-jss-components/lib/components/RecommendedVehicles';
import SectionContainer from '@honda-canada/sitecore-jss-components/lib/components/SectionContainer';
import SectionNavigation from '@honda-canada/sitecore-jss-components/lib/components/SectionNavigation';
import ShowcaseCard from '@honda-canada/sitecore-jss-components/lib/components/ShowcaseCard';
import SideNav from '@honda-canada/sitecore-jss-components/lib/components/SideNav';
import SideNavLayout from '@honda-canada/sitecore-jss-components/lib/components/SideNavLayout';
import SideNavScrollIndicator from '@honda-canada/sitecore-jss-components/lib/components/SideNavScrollIndicator';
import SidebarLayout from '@honda-canada/sitecore-jss-components/lib/components/SidebarLayout';
import Slider from '@honda-canada/sitecore-jss-components/lib/components/Slider';
import SummaryBar from '@honda-canada/sitecore-jss-components/lib/components/SummaryBar';
import SummaryCard from '@honda-canada/sitecore-jss-components/lib/components/BapSummaryCard';
import Tab from '@honda-canada/sitecore-jss-components/lib/components/Tab';
import Table from '@honda-canada/sitecore-jss-components/lib/components/Table';
import TableStyle2 from '@honda-canada/sitecore-jss-components/lib/components/TableStyle2';
import TabsContainer from '@honda-canada/sitecore-jss-components/lib/components/TabsContainer';
import ThreeColumnLayout from '@honda-canada/sitecore-jss-components/lib/components/ThreeColumnLayout';
import ThreeSixtyView from '@honda-canada/sitecore-jss-components/lib/components/ThreeSixtyView';
import Title from '@honda-canada/sitecore-jss-components/lib/components/Title';
import TrimCardGrid from '@honda-canada/sitecore-jss-components/lib/components/TrimCardGrid';
import TrimOverview from '@honda-canada/sitecore-jss-components/lib/components/TrimOverview';
import TrimPackages from '@honda-canada/sitecore-jss-components/lib/components/TrimPackages';
import TrimPayment from '@honda-canada/sitecore-jss-components/lib/components/TrimPayment';
import TrimSelector from '@honda-canada/sitecore-jss-components/lib/components/TrimSelector';
import TrimSpecifications from '@honda-canada/sitecore-jss-components/lib/components/TrimSpecifications';
import UnOrderedList from '@honda-canada/sitecore-jss-components/lib/components/UnOrderedList';
import VINSearchApp from '@honda-canada/react-subapp-vin-search/lib/components/VINSearchApp';
import VehicleListFilters from '@honda-canada/sitecore-jss-components/lib/components/VehicleListFilters';
import VideoComponent from '@honda-canada/sitecore-jss-components/lib/components/VideoComponent';
import ViewTrimHighlights from '@honda-canada/sitecore-jss-components/lib/components/ViewTrimHighlights';
import WarrantyOptions from '@honda-canada/sitecore-jss-components/lib/components/WarrantyOptions';

const components = new Map();
components.set('360ModelCard', ThreeSixtyView);
components.set('Accessories', Accessories);
components.set('AnchorContainer', AnchorContainer);
components.set('AppBanner', AppBanner);
components.set('AppCard', AppCard);
components.set('AppHero', AppHero);
components.set('AvailableColors', AvailableColors);
components.set('Awards', Awards);
components.set('BapSummaryCard', SummaryCard);
components.set('BrandNavigation', BrandNavigation);
components.set('CarouselCardsContainer', CarouselCardsContainer);
components.set('CarouselHero', CarouselHero);
components.set('CarouselWithModalContainer', CarouselWithModalContainer);
components.set('CollapsibleContainer', CollapsibleContainer);
components.set('ColorDisplay', ColorDisplay);
components.set('CompareTrimSpecifications', CompareTrimSpecifications);
components.set('ConfigureBap', ConfigureBap);
components.set('ContactUs', ContactUs);
components.set('CreditAndSignupCtas', CreditAndSignupCtas);
components.set('CtaCard', CtaCard);
components.set('CustomizationSummary', CustomizationSummary);
components.set('DealerLocator', DealerLocator);
components.set('DiagonalCard', DiagonalCard);
components.set('DiagonalCardContainer', DiagonalCardContainer);
components.set('DividerLine', DividerLine);
components.set('DropdownFilterContainer', DropdownFilterContainer);
components.set('DropdownToggleContainer', DropdownToggleContainer);
components.set('DropdownToggleItem', DropdownToggleItem);
components.set('FaqContent', FAQ);
components.set('FiveCellLayout', CustomLayoutFactory);
components.set('FiveColumnLayout', LayoutContainer);
components.set('FooterNavigation', Footer);
components.set('FourColumnLayout', FourColumnLayoutContainer);
components.set('FreeFormText', FreeFormText);
components.set('FullWidthCard', FullWidthCard);
components.set('FutureVehicleCard', FutureVehicleCard);
components.set('Gallery', Gallery);
components.set('Hero', Hero);
components.set('HoverCard', HoverCard);
components.set('Icon', Icon);
components.set('IconCard', IconCard);
components.set('KeyFeaturesHero', KeyFeatures);
components.set('LandingPageCard', LandingPageCard);
components.set('LandscapeBoxCard', LandscapeBoxCard);
components.set('LandscapeCard', LandscapeCard);
components.set('LeadsCtas', LeadsCtas);
components.set('LinkPackageCard', LinkPackageCard);
components.set('ModelCard2', ModelCard);
components.set('ModelCardGrid', ModelCardGrid);
components.set('ModelCardWithTrims', ModelCardWithTrims);
components.set('ModelFeatures', ModelFeatures);
components.set('ModelListFilters', ModelListFilters);
components.set('ModelPageNav', ModelPageNav);
components.set('NotificationPopUp', NotificationPopup);
components.set('PackageCard', PackageCard);
components.set('PageNotification', PageNotification);
components.set('Parallax', Parallax);
components.set('PlanCard2', PlanCard2);
components.set('PortraitCard', PortraitCard);
components.set('PreOrderBanner', PreOrderBanner);
components.set('ProgressBar', ProgressBar);
components.set('PromoBanner', PromoBanner);
components.set('QuickLink', QuickLink);
components.set('RecommendedVehicles', RecommendedVehicles);
components.set('SectionContainer', SectionContainer);
components.set('SectionNav', SectionNavigation);
components.set('ShowcaseCard', ShowcaseCard);
components.set('SidebarLayout', SidebarLayout);
components.set('SideNav', SideNav);
components.set('SideNavLayout', SideNavLayout);
components.set('SideNavScrollIndicator', SideNavScrollIndicator);
components.set('SingleColumnLayout', LayoutContainer);
components.set('SiteLogo', () => null); // to prevent 'missing JSS component' error
components.set('SixCellLayout', CustomLayoutFactory);
components.set('Slider', Slider);
components.set('SummaryBar', SummaryBar);
components.set('Tab', Tab);
components.set('Table', Table);
components.set('TableStyle2', TableStyle2);
components.set('TabsContainer', TabsContainer);
components.set('ThreeCellLayout', CustomLayoutFactory);
components.set('ThreeColumnLayout', ThreeColumnLayout);
components.set('Title', Title);
components.set('TopNavigation', Header);
components.set('TrimCardGrid', TrimCardGrid);
components.set('TrimOverview', TrimOverview);
components.set('TrimPackages', TrimPackages);
components.set('TrimPayment', TrimPayment);
components.set('TrimSelector', TrimSelector);
components.set('TrimSpecifications', TrimSpecifications);
components.set('TwoCardUpLayout', CustomLayoutFactory);
components.set('TwoColumnLayout', LayoutContainer);
components.set('UnOrderedList', UnOrderedList);
components.set('VehicleListFilter', VehicleListFilters);
components.set('VideoComponent', VideoComponent);
components.set('ViewTrimHighlights', ViewTrimHighlights);
components.set('VinRecalls', VINSearchApp);
components.set('WarrantyOptions', WarrantyOptions);

export default function componentFactory(componentName) {
  return components.get(componentName);
}
